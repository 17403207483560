<template>
  <div class="serve-container pc-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <img class="box-bg" :src="serveTopBg1" alt="" />

      <div class="describe-box is-wrapper-box">
        <div class="row row-1 fontBold">一站式软件开发服务</div>

        <div class="row row-2 fontRegular">
          我们支持从梳理需求到上架的全流程服务支持，
          选择我们让您更省心、更放心、更安心！
        </div>

        <div class="row-3">
          <div class="btn-wrap">
            <div class="btn" @click="jumpFormScrollTop">
              <button class="fontRegular">联系我们</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子2 -->
    <div class="wrapper-box wrapper-box-2">
      <div class="wrapper-content">
        <div
          v-for="(item, index) in wrappBox2__list1"
          :key="'title' + index"
          :class="item.direction == 'left' ? 'left' : 'right'"
        >
          <div class="item-wrap">
            <div class="wrapp-title">
              <div class="sort">
                <img :src="item.icon" alt="" />
                <div class="title">
                  <div class="text fontBold">{{ item.title }}</div>
                  <div class="border-bar">
                    <img :src="borderBarImage" alt="" />
                  </div>
                </div>
              </div>
              <div class="sub-title fontRegular">
                {{ item.text }}
              </div>
            </div>

            <div class="wrapp-group-box">
              <div class="image">
                <img :src="item.image" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子7 -->
    <div class="wrapper-box wrapper-box-3">
      <img class="box-bg" :src="homeTopBg3" alt="" />

      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from "vue";
import datajs from "../data.js";
import anime from "animejs";

const { proxy } = getCurrentInstance();
// 辅助参数
const { wrappBox2__list1, jumpFormScrollTop } = datajs.apply(proxy);

// 图片
// 首页顶部背景
const serveTopBg1 = require("@/assets/images/serve-top-bg-1.png");
// 首页底部表单背景
const homeTopBg3 = require("@/assets/images/home-top-bg-3.png");
// 边线标识
const borderBarImage = require("@/assets/images/home/border-bar.png");

//  定位底部表单
// const jumpFormScrollTop = () => {
//   const call = () => {
//     const scrollTop =
//       document.getElementsByClassName("footer-container")[0].offsetTop;
      // window.scrollTo({ top: scrollTop });
//   };

//   // 记录鼠标操作
//   dataStore.setGlobalClickRecord(call);
//   call();
// };

// 设置一些动画
const setPageSomeAnimation = () => {
  anime
    .timeline({
      direction: "reverse",
      easing: "easeInOutSine",
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-3",
      translateX: 140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-2",
      translateX: -140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-1",
      translateX: 140,
      opacity: 0,
    });
};

onMounted(() => {
  setPageSomeAnimation();
});
</script>

<style lang="scss" scoped>
.serve-container {
  &.pc-wrap {
    .is-wrapper-box {
      // width: calc(100vw - 720px);
      width: 100%;
      max-width: 1200px;
      margin: auto;
    }
    .wrapper-box {
      .wrapp-title {
        text-align: center;
        h2 {
          font-size: 38px;
          color: rgba(51, 51, 51, 1);
        }
        .sub-title {
          font-size: 16px;
          color: rgba(102, 102, 102, 1);
          line-height: 50px;
        }
      }

      .wrapper-content {
        .list-more {
          margin-top: 80px;
          text-align: center;
          img {
            margin: auto;
            width: 160px;
            height: 48px;
          }
        }
      }

      // 盒子1
      &-1 {
        // background-image: url(../../../assets/images/serve-top-bg-1.png);
        // background-repeat: no-repeat;
        // background-attachment: fixed;
        // background-position: center 70px;
        // background-size: auto 720px;
        padding: 60px 0;
        width: 100%;
        height: 100%;
        max-height: 5000px;
        position: relative;
        .box-bg {
          width: 100%;
          position: absolute;
          z-index: -1;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          top: 0;
          left: 0;
        }

        .describe-box {
          margin: 0 auto;
          padding-top: 187px;
          padding-bottom: 173px;
          position: relative;
          .row {
            text-align: start;
            color: rgba(51, 51, 51, 1);
            &-1 {
              font-size: 80px;
              font-weight: bold;
            }
            &-2 {
              font-size: 32px;
              margin: 44px 0;
              width: 672px;
              line-height: 54px;
            }
            &-3 {
              .btn-wrap {
                .btn {
                  display: flex;
                  justify-content: flex-start;

                  button {
                    width: 160px;
                    height: 48px;
                    background: #0a41ff;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 2px;

                    &:active {
                      background-color: #0a41ffcf;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 盒子2
      &-2 {
        .wrapper-content {
          margin: auto;

          .item-wrap {
            display: flex;
            width: 100%;
            max-width: 1200px;
            margin: auto;
            align-items: center;
          }

          .left {
            display: flex;
            justify-content: center;
            background-color: #fff;
            align-items: center;
            padding: 75px 0;
            box-sizing: border-box;

            .item-wrap {
              flex-direction: row;
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            background-color: rgba(247, 250, 253, 1);
            padding: 75px 0;
            box-sizing: border-box;

            .item-wrap {
              flex-direction: row-reverse;
              .wrapp-title {
                margin-left: 60px;
              }
            }
          }

          .wrapp-title {
            margin-right: 60px;
            .sort {
              width: 189px;
              height: 200px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
              }
              .title {
                position: absolute;
                top: 90px;
                .text {
                  font-size: 26px;
                  font-weight: bold;
                  text-align: start;
                  color: rgba(51, 51, 51, 1);
                }
                .border-bar {
                  width: 25px;
                  height: 4px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .sub-title {
              font-size: 30px;
              margin-bottom: 30px;
              color: #999999;
              width: 350px;
              font-size: 16px;
              line-height: 36px;
              text-align: start;
            }
          }
          .wrapp-group-box {
            .image {
              margin: auto;
              width: 100%;
              height: auto;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      &-3 {
        // background-image: url(../../../assets/images/home-top-bg-3.png);
        // background-repeat: no-repeat;
        // background-position: center 0;
        // background-size: auto 584px;
        // padding: 70px 0;

        padding: 60px 0;
        width: 100%;
        height: 100%;
        max-height: 5000px;
        position: relative;
        .box-bg {
          width: 100%;
          position: absolute;
          z-index: -1;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
