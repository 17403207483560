import { nextTick, reactive, ref, watch } from "vue";

export default function () {
  const proxy = this;
  const { mainStore, dataStore } = proxy.$usePiniaModule();

  /* 盒子2 */
  // 列表1
  const wrappBox2__list1 = [
    {
      title: "初步沟通",
      text: "沟通了解需求&初步规划需求，确认项目的可行性、合法性等。确保避免未来因技术无法开发造成的无效沟通。",
      icon: require("@/assets/images/serve/serve-step1.png"),
      direction: "left",
      image: require("@/assets/images/home/flow-path-0.png"),
    },
    {
      title: "确认合作",
      text: "输出《功能清单》确认项目1.0版本、合同范围及边界签订合同。前期耗时整理的需求清晰明了，云效避免需求模糊进入合作后条条款款都要收款“先上车后补票”的行径。随后确认整体需求进行工作计划安排输出《需求工作计划》；tips：按计划实施工作可以对整个项目的大体时间流程清晰把控。",
      icon: require("@/assets/images/serve/serve-step2.png"),
      direction: "right",
      image: require("@/assets/images/home/flow-path-1.png"),
    },
    {
      title: "设计原型",
      text: "产品经理进行精细化需求了解和梳理后，由粗到细输出：《流程图》、《状态图》、《原型架构》、《原型设计》进一步明确页面&页面跳转交互效果。",
      icon: require("@/assets/images/serve/serve-step3.png"),
      direction: "left",
      image: require("@/assets/images/home/flow-path-2.png"),
    },
    {
      title: "UI设计",
      text: "UI设计师了解项目定位与客户期望后，进行风格稿的设计，并附上设计理念与设计规范给客户确认，待客户选定UI风格稿以后开始UI的设计。在设计过程中会充分考虑交互与规范。",
      icon: require("@/assets/images/serve/serve-step4.png"),
      direction: "right",
      image: require("@/assets/images/home/flow-path-3.png"),
    },
    {
      title: "开发项目",
      text: "确认需求与Ui设计后进入开发阶段，输出《项目开发计划》并按计划进行每日开发实施，同时输出文件《数据库设计》、《数据字典》、《接口文档》、《项目部署文档》等文件。",
      icon: require("@/assets/images/serve/serve-step5.png"),
      direction: "left",
      image: require("@/assets/images/home/flow-path-4.png"),
    },
    {
      title: "测试项目",
      text: "开发过程中同步测试项目进行单元测试，按照开发计划项目开发完成后会进行全面系统测试，输出《测试用例》、《测试报告》。",
      icon: require("@/assets/images/serve/serve-step6.png"),
      direction: "right",
      image: require("@/assets/images/home/flow-path-5.png"),
    },
    {
      title: "验收项目",
      text: "当项目符合验收标准后云效会发起验收，按照《验收报告》进行逐条检验功能，确定没问题后用户签订验收报告，正式进入交付环节。",
      icon: require("@/assets/images/serve/serve-step7.png"),
      direction: "left",
      image: require("@/assets/images/home/flow-path-6.png"),
    },
    {
      title: "交付项目",
      text: "签订《验收报告》后云效会收集项目开发过程中产生的所有文档及源代码进行交付到合同约定的邮箱账户。",
      icon: require("@/assets/images/serve/serve-step8.png"),
      direction: "right",
      image: require("@/assets/images/home/flow-path-7.png"),
    },
  ];

  // **************************************************************************************
  // 操作 START
  // **************************************************************************************

  //  定位底部表单
  const jumpFormScrollTop = () => {
    const call = () => {
      const scrollTop =
        document.getElementsByClassName("footer-container")[0].offsetTop;
      window.scrollTo({ top: scrollTop });
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document.getElementsByClassName("btn-wrap")[0].click();
    });
    call();
  };

  // **************************************************************************************
  // 操作 END
  // **************************************************************************************

  return {
    wrappBox2__list1,
    jumpFormScrollTop,
  };
}
